import { HeadingSection } from "../HeadingSection"
import React, { useEffect } from "react"
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io"
import { CardProduct } from "../../CardProduct"
import { useMediaQuery } from "@chakra-ui/react"

export function BrindesParaEventos({ productsEvents }) {
  const [visibleSlides, setVisibleSlides] = React.useState(3)
  const [isDesktop] = useMediaQuery("(min-width: 1024px)")
  const [isMobile] = useMediaQuery("(max-width: 1023px)")
  const [isTablet] = useMediaQuery("(min-width: 768px)")

  useEffect(() => {
    setVisibleSlides(isDesktop ? 3 : isTablet ? 2 : isMobile ? 1 : 3)
  }, [isDesktop, isTablet, isMobile])

  return (
    <section className="flex flex-col gap-4">
      <HeadingSection text="Brindes para eventos" />

      <CarouselProvider
        naturalSlideWidth={397}
        naturalSlideHeight={391}
        totalSlides={21} // Total de slides (quantidade de brindes)
        visibleSlides={visibleSlides} // Quantidade de slides visíveis ao mesmo tempo
        isIntrinsicHeight={true}
        isPlaying
        infinite={true} // Para permitir rolagem infinita
        className="relative"
      >
        <Slider>
          {/* Slide 1 */}
          {productsEvents.map((item, index) => (
            <Slide key={index} index={index} className="py-2">
              <div className="flex mx-auto flex-col items-center">
                <CardProduct item={item} />
              </div>
            </Slide>
          ))}
        </Slider>

        <ButtonBack className="absolute left-0 border top-1/2 transform -translate-y-1/2 flex items-center justify-center w-10 h-10 rounded-full bg-white shadow-md">
          <IoIosArrowBack className="text-2xl" />
        </ButtonBack>
        <ButtonNext className="absolute right-0 border top-1/2 transform -translate-y-1/2 flex items-center justify-center w-10 h-10 rounded-full bg-white shadow-md">
          <IoIosArrowForward className="text-2xl" />
        </ButtonNext>
      </CarouselProvider>
    </section>
  )
}
